import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import CreateUserView from "@/views/CreateUserView.vue";
import CommandView from "@/views/CommandView.vue";
import ListUserView from "@/views/ListUserView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        name: 'home-view',
        component: HomeView
    },
    {
        path:'/list/users',
        name: 'list-user-view',
        component: ListUserView
    },
    {
        path:'/create/user',
        name: 'create-user-view',
        component: CreateUserView,
        props: true
    },
    {
        path:'/create/command',
        name: 'command-view',
        component: CommandView
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
    base: './'
})

export default router;