<template>
  <main>
    <div class="main-container">
      <h3 class="custom-title text-center text-primary">Nouvelle commande</h3>

      <!-- Form Command -->
      <b-form @submit="onSubmit" id="form_command">
        <div>
          <p class="text-primary">Client</p>
          <vue-typeahead-bootstrap id="client"
                                   :data=options
                                   v-model="form.user_id"
                                   placeholder="Nom du client"
          /> <!-- auto completion client -->
        </div>
        <p class="text-primary">Date</p>
        <b-form-input v-model="form.date" placeholder="Date" type="date"></b-form-input>
        <p class="text-primary">Horaire</p>
        <b-form-input v-model="form.hour" placeholder="Heure" type="time"></b-form-input>
        <b-button variant="primary" type="submit">Go!</b-button>
      </b-form>

    </div>
  </main>
</template>

<script>

import {create_command, get_all_users} from "@/services/ApiServices";

export default {
  name: "command-view",
  data: () => {
    return {
      form: {
        user_id: '',
        date: '',
        hour: ''
      },
      user: '',
      options: []
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      create_command(this.form).then(response => {
        window.location.href = '/';
      })
    }
  },
  created() {
    get_all_users().then(response => {
      this.options = response
    })
  }
}

</script>

<style scoped>

p {
  margin-bottom: 0px;
  margin-top: 10px;
}

#client {
  margin-top: 0px
}

input {
  margin: 10px 0;
}

</style>