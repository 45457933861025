<script>
export default {
  name: "CustomFooter"
}
</script>

<template>
  <footer class="main">
    <p>© 2024 Gestion des livraisons CB Inc. v.1.0.2</p>
  </footer>
</template>

<style scoped lang="scss">

p {
  text-align: center;
  color: rgba(129, 126, 126, 0.65);
  font-size: 12px;
  margin-top: 3vh;
  margin-bottom: 0;
}
</style>