<script>
export default {
  name: "SearchFilter",
  methods: {
    search() {
      return this.$emit('search', this.input)
    }
  },
  data() {
    return {
      input: ''
    }
  }
}


</script>

<template>
        <label class="w-100 d-flex">
          <font-awesome-icon class="glass" icon="fa-solid fa-magnifying-glass"/>
          <input type="text" v-model="input" @input="search" class="search-bar" placeholder="Chercher un client..."/>
        </label>
</template>

<style scoped lang="scss">
.search-bar {
  width: 100%;
  border-radius: 12px;
  padding: 10px 10px 10px 35px;
  border: solid 1px lightgray;
  margin-bottom: 15px;
}

.glass {
  z-index: 1;
  margin: 14px -29px 0px 12px;
  color: gray;
}

input:focus {
  outline-color: gray;
}
</style>