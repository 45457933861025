const SERVEUR_ERROR_MSG = "Impossible de se connecter au serveur, contactez l'administrateur"
async function manageResponse(response) {
    if (!response.ok) {
        let errorMessage = await response.text() || "Une erreur s'est produite"
        throw new Error(errorMessage)
    }
    return await response.json();
}

export async function call_drive(url) {
    try {
        const response = await fetch(process.env.VUE_APP_API_URL + url)
        return manageResponse(response)
    } catch (error) {
        console.log(error)
        throw new Error(SERVEUR_ERROR_MSG)
    }

}

export async function post_drive(url, data) {
    try {
        const response = await fetch(process.env.VUE_APP_API_URL + url, {
            method: "POST",
            body: data
        });
        return manageResponse(response)
    } catch (error) {
        console.log(error)
        throw new Error(SERVEUR_ERROR_MSG)
    }
}

export async function get_commands() {
    return await call_drive('/commands/all');
}

export async function delete_command(id) {
    return await call_drive('/commands/delete/' + id);
}

export async function create_command(form) {
    return await post_drive('/commands/create', JSON.stringify(form));
}

export async function create_user(form) {
    return await post_drive('/commands/create/user', JSON.stringify(form))
}

export async function delete_user(id) {
    return await call_drive('/commands/delete/user/' + id);
}

export async function update_user(id, form) {
    return await post_drive('/commands/update/user/' + id, JSON.stringify(form));
}

export async function get_all_users() {
    return await call_drive('/commands/all/users');
}

export async function get_all_detail_users() {
    return await call_drive('/commands/all/detail/users');
}