<template>
  <main>
    <div class="main-container">

      <b-alert v-model="error" variant="danger" dismissible>
        {{ msg_alert }}
      </b-alert>

      <h3 class="custom-title text-center text-primary mb-4">{{ title }} client</h3>

      <!-- Form User -->
      <b-form @submit="onSubmit" id="form_send">
        <b-form-input v-model="form.name" placeholder="Nom"></b-form-input>
        <b-form-input v-model="form.address" placeholder="Adresse"></b-form-input>
        <b-form-input v-model="form.tel" placeholder="Téléphone" type="tel"></b-form-input>
        <b-form-input v-model="form.fid" placeholder="Carte de fidélité"></b-form-input>
        <b-form-input v-model="form.notes" placeholder="Notes"></b-form-input>

        <b-button variant="primary" type="submit">Go!</b-button>
      </b-form>

    </div>
  </main>

</template>

<style lang="scss" scoped>

@import "src/assets/styles/custom_variables";

span.float-right {
  cursor: pointer;
  margin-left: 5%;
}

input {
  margin: 20px 0;
}
</style>

<script>

import {create_user, update_user} from "@/services/ApiServices";

export default {
  name: "create-user-view",
  props: ['user', 'is_update'],
  data: () => {
    return {
      is_update: false,
      title: "Nouveau",
      error: false,
      msg_alert: '',
      form: {
        name: '',
        address: '',
        tel: '',
        fid: '',
        notes: ''
      }
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      if (this.is_update) {
        update_user(this.form.id, this.form).then(_ => {
          window.location.href = '/list/users';
        }).catch(error => {
          this.error = true;
          this.msg_alert = error.message
        })
      } else {
        create_user(this.form).then(_ => {
          window.location.href = '/';
        }).catch(error => {
          this.error = true;
          this.msg_alert = error.message
        })
      }
    }
  },
  mounted() {
    if (this.user) { // if user pass in parameter, update it else create new
      this.is_update = true
      this.form = this.user
      this.title = "Modification"
    }
  }
}
</script>