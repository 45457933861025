<template>
  <main>
    <div class="main-container">

      <b-alert v-model="error" variant="danger" dismissible>
        {{ msg_alert }}
      </b-alert>

      <div class="h-100">

        <b-button href="/create/command" variant="primary"> Nouvelle commande</b-button>
        <b-button href="/create/user" variant="primary"> Nouveau client</b-button>

        <div class="mt-4">
          <b-card-group deck>
            <b-card v-for='command in commands'>
              <b-card-text>
                <div class="d-flex">
                  <p class="text-primary">N°{{ command.id }}</p>
                  <a id="trash" @click="deleteCommand(command.id)">
                    <font-awesome-icon class="text-primary" icon="fa-solid fa-trash"/>
                  </a>
                </div>
                <p>Date: {{ command.date }}</p>
                <p>{{ command.name }}</p>
                <p> {{ command.address }}</p>
                <p v-if="command.tel">Tel: {{ command.tel }}</p>
                <p v-if="command.notes">Notes: {{ command.notes }}</p>
              </b-card-text>
            </b-card>
          </b-card-group>
        </div>

      </div>

    </div>
  </main>
</template>

<style lang="scss" scoped>

a {
  width: 100%;
  margin: 10px auto;
  display: flex;
  max-width: 295px;
  justify-content: center;
}

#trash {
  margin: 0 0 0 auto;
  width: auto;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media(min-width: 576px) {
  .card-deck .card {
    min-width: 28%;
    max-width: 28%;
  }

  a {
    margin: 10px 0;
  }
}

p {
  margin-bottom: 0;
}
</style>

<script>

import {delete_command, get_commands} from "@/services/ApiServices";

export default {
  name: 'home-view',
  data() {
    return {
      commands: [],
      error: false,
      msg_alert: ''
    }
  },
  methods: {
    deleteCommand(command_id) {
      delete_command(command_id).then(response => {
        this.commands = response
      })
    }
  },
  created() {
    get_commands().then(response => {
      this.commands = response;
    }).catch(error => {
      this.error = true;
      this.msg_alert = error.message
    })
  }
}
</script>