import Vue from 'vue'

// import scss
import './assets/styles/index.scss'

import App from './App.vue'
import router from './router'
import {BootstrapVue, CardPlugin, FormFilePlugin, FormPlugin, PaginationPlugin, FormSelectPlugin} from 'bootstrap-vue'
//Font awesome
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faDownload, faMagnifyingGlass, faTrash, faXmark, faPencil} from '@fortawesome/free-solid-svg-icons'
//Spinner: https://github.com/pacifio/vue-spinners
import VueSpinners from 'vue-spinners'
// V-viewer
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';


Vue.use(BootstrapVue)

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faDownload, faMagnifyingGlass, faTrash, faXmark, faPencil)

Vue.use(VueSpinners)
Vue.use(VueViewer)
Vue.use(PaginationPlugin)
Vue.use(CardPlugin)
Vue.use(FormPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormSelectPlugin)

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)


new Vue({
    router,
    render: (h) => h(App)
}).$mount('#app')