<template>
  <main>
    <div class="main-container">

      <b-alert v-model="error" variant="danger" dismissible>
        {{ msg_alert }}
      </b-alert>

      <div class="h-100">

        <SearchFilter @search="refresh"/>
        <b-button href="/create/user" variant="primary"> Nouveau client</b-button>

        <div class="mt-4">
          <b-card-group deck>
            <b-card v-for='user in get_filter_client()'>
              <b-card-text>
                <div class="d-flex">
                  <p class="text-primary">{{ user.name }}</p>
                  <router-link class="icon-style" :to="{ name: 'create-user-view', params: { user: user } }">
                    <font-awesome-icon class="text-primary" icon="fa-solid fa-pencil"/>
                  </router-link>
                  <a @click="deleteUser(user.id)" class="ml-4 icon-style">
                    <font-awesome-icon class="text-primary" icon="fa-solid fa-trash"/>
                  </a>
                </div>
                <p> {{ user.address }}</p>
                <p v-if="user.tel">Tel: {{ user.tel }}</p>
                <p v-if="user.fid">Carte fidélité: {{ user.fid }}</p>
                <p v-if="user.notes">Notes: {{ user.notes }}</p>
              </b-card-text>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {delete_user, get_all_detail_users} from "@/services/ApiServices";
import SearchFilter from "@/components/SearchFilter.vue";

export default {
  name: "list-user-view",
  components: {SearchFilter},
  data() {
    return {
      users: [],
      input: '',
      error: false,
      msg_alert: '',
    }
  },
  methods: {
    displayAlert(is_success, msg = "L'utilisateur a bien été créé") {
      this.visible_alert = true;
      this.msg_alert = msg;
      this.class_alert = is_success ? "success" : "danger";
    },
    refresh(data) {
      this.input = data
    },
    get_filter_client() {
      return this.users.filter((item) => { // filter in name
            const f_item = item['name'].toLowerCase()
            return f_item.includes(this.input.toLowerCase())
          }
      );
    },
    deleteUser(user_id) {
      delete_user(user_id).then(response => {
        this.users = response
      }).catch(error => {
        this.error = true;
        this.msg_alert = error.message
      });
    }
  },
  created() {
    get_all_detail_users().then(response => {
      this.users = response;
    }).catch(error => {
      this.error = true;
      this.msg_alert = error.message
    })
  }
}
</script>

<style scoped>

.icon-style {
  margin: 0 0 0 auto;
  width: auto;
  font-size: 19px;
}

.card-deck .card {
  margin-bottom: 15px;
}

.btn {
  margin: 10px auto;
  display: flex;
  max-width: 295px;
  justify-content: center;
}

@media(min-width: 576px) {
  .card-deck .card {
    min-width: 28%;
    max-width: 28%;
  }

  a {
    margin: 10px 0;
  }

  .btn {
    margin: 10px 0;
  }
}

</style>